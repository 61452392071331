<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Notas de crédito</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
                        Filtros
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-btn
                        dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="credit_notes"
                            item-key="id"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >
                            <template v-slot:item.credit_note_date="{item}">
                                {{ item.credit_note_date | moment('DD/MM/YYYY') }}
                            </template>

                            <template v-slot:item.sale_serie="{item}">
                                {{ item.sale_serie }}-{{ item.sale_correlative }}
                            </template>

                            <template v-slot:item.sale_total="{item}">
                                {{ item.sale_total | currency('S/') }}
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-icon size="16" color="blue"
                                    @click="handlePrintCreditNote(item)">
                                    fas fa-print
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <credit-filters ref="creditFilters"></credit-filters>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import CreditFilters from '../../../components/reports/CreditFilters';

import CreditNoteTicketTools from '../../../helpers/CreditNoteTicketTools';

import JQuery from 'jquery';
let $ = JQuery;

export default {
    name: 'CreditNotes',
    components: {
        CreditFilters,
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'LUGAR', value: 'office_name', sortable: false },
                { text: 'FECHA', value: 'credit_note_date', sortable: false },
                { text: 'SERIE', value: 'credit_note_serie', sortable: false },
                { text: 'N° DOC.', value: 'credit_note_correlative', sortable: false },
                { text: 'CLIENTE', value: 'customer_name', sortable: false },
                { text: 'RUC/DNI.', value: 'customer_document', sortable: false },
                { text: 'DOC. REF.', value: 'sale_serie', sortable: false, width: 140 },
                { text: 'TOTAL DOC. REF.', value: 'sale_total', sortable: false, align: 'end', width: 120 },              
                { text: '', value: 'actions', sortable: false, align: 'end', witdh: 50 }
            ],
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('credit-notes', [
            'credit_notes',
            'filters',
            'file_name'
        ]),
        ...mapState('report-sales', [
            'voucher'
        ])
    },
    methods: {
        ...mapActions('credit-notes', [
            'getCreditNotes',
            'downloadExcel'
        ]),
        ...mapActions('report-sales', [
            'getSaleVoucher'
        ]),
        handleShowFilters () {
            this.$refs.creditFilters.show();
        },
        async handleDownloadReport () {
            let filters_data = Object.assign({}, this.filters);
            filters_data.company_id = this.company_id;
            await this.downloadExcel(filters_data);
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            }
        },
        async handlePrintCreditNote (credit_note) {
            await this.getSaleVoucher(credit_note.sale_id);
            if (this.voucher) {
                let content = await CreditNoteTicketTools.printTicket(credit_note, this.voucher);
                this.printVoucher(content);
            }            
        },
        printVoucher (content) {
            $('<iframe>', {name: 'voucheriframe',class: 'printFrame'})
            .appendTo('body')
            .contents().find('body')
            .append(content);

            window.frames['voucheriframe'].focus();

            setTimeout(() => {                 
                window.frames['voucheriframe'].print();
             }, 400);

            setTimeout(() => { $(".printFrame").remove(); }, 1000);
        }        
    },
    created () {
        this.getCreditNotes({
            company_id: this.company_id
        });
    }
}
</script>

<style scoped>

</style>