<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>FILTRAR DATOS DE NOTAS DE CRÉDITO</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuFrom"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    label="Desde"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" 
                                @input="menuFrom = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menuUntil"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_until"
                                    label="Hasta"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    hide-details                         
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_until" 
                                @input="menuUntil = false" 
                                no-title
                                locale="es">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'CreditFilters',
    data () {
        return {
            drawer: false,
            menuFrom: false,
            menuUntil: false,
            filters: {
                company_id: null,
                date_from: null,
                date_until: null
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ])
    },
    methods: {
        ...mapActions('credit-notes', [
            'getCreditNotes',
            'setFilters'
        ]),
        show () {
            this.filters.company_id = this.company_id;
            this.drawer = !this.drawer;
        },
        closeForm () {
            this.drawer = false;
        },
        clearData () {
            this.filters.date_from = null;
            this.filters.date_until = null;
            this.menuFrom = false;
            this.menuUntil = false;
            this.setFilters(null);
        },
        handleClearFilters () {
            this.clearData();
            this.getCreditNotes(this.filters);
        },
        async handleSearch () {
            let filters_data = Object.assign({}, this.filters);
            this.setFilters(filters_data);
            await this.getCreditNotes(this.filters);
            this.closeForm();
        }
    }
}
</script>

<style scoped>

</style>